import styled from 'styled-components';
export const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family:
    Phi,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  // color: black;
  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
`;
export const StyledCompany = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family:
    Phi,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  // color: black;
  @media screen and (max-width: 1200px) {
    font-size: 12px;
  }
`;
export const StyledText = styled.div`
  font-size: 16px;
  font-weight: 200;
  font-family:
    Phi,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  // color: #333;
  @media screen and (max-width: 1200px) {
    font-size: 10px;
  }
`;
